<header class="header header-admin-user ">
    <div class="panel-hamburger" style="float: left;">
      <a id="hamburger" class="toggle-dashboard-nav hamburger-icon-container" (click)="togglehamburger()">
        <span class="hamburger-icon"></span>
      </a>
    </div>

  <a routerLink="/" class="logo">
    Applis
  </a>

  <div class="content-menu-right">
    <div class="descript-content">
      <div class="title-page-dash">
        Tableau de bord
      </div>
      <div class="libelle-page">
        Espace Dev
      </div>
    </div>

    <input class="menu-btn" type="checkbox" id="menu-btn" />
    <label class="menu-icon" for="menu-btn"><span class="navicon"></span></label>
    <ul class="menu">
      <li>
        <a href="#work">
          <img src="assets/images/help-questionmark_430101.png" alt=""  style="height: 37px;"/>
        </a>
      </li>
      
      <li>
        <a routerLink="/dashboard/applis/autorisees">
          <span class="item-link-applis">
            Mes Applis
          </span>
        </a>
      </li>
      <li>
          <a href="javascript:void(0)" class="open-profils-user" (click)="opendropDown()">
            <span *ngIf="currentuser != null">
              <img src="{{ currentuser.imgprofil }}" alt=".."/>
              <span class="pseudo-user">
                {{ currentuser.firstname }} {{ currentuser.lastname }}
              </span>
            </span>
            <span class="fa fa-chevron-down"></span>
          </a>
          <div class="profil-dropdown">
            <a routerLink="/accueil" style="line-height: 15px;">
              <img src="{{ currentuser.imgprofil }}" alt=".." style="float: left; margin-right: 7px; height: 50px; width: 50px; border-radius: 50%;"/>
              <h5><strong>{{ currentuser.firstname }} {{ currentuser.lastname }}</strong></h5>
              <div class="">
                Compte Particulier
              </div>
            </a>
            <a routerLink="update/account" style="line-height: 15px;">
              <h5><strong>Paramètres</strong></h5>
              <div class="">
                Modifier les accès de ce compte.
              </div>
            </a>
          </div>
      </li>
    </ul>
  </div>
</header>
