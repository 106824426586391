
<main class="d-flex align-items-center min-vh-100 py-3 py-md-0">
  <div class="container">
    <div class="card login-card">
      <div class="row no-gutters">
        <div class="col-md-5">
     
          <div class="login-card-img">
            <div class="text-center mg-t-xs-medium">
                <img src="../assets/images/img-login.jpg" alt="" style="max-width: 100%;"/>
            </div>

            <h3 class="app-title">
              Connectez vous à plus de 06 Applications avec  <strong style="color: #325ea9;">un seul compte AFHunt</strong>
            </h3>

            <div class="text-left">
              <span class="app-title-diviser"></span>
            </div>
          </div>

        </div>
        <div class="col-md-7">
          <div class="card-body">
            <div class="login-card-description" style="font-family: Raleway-Medium;">
              <strong style="color: #325ea9;">Inscription</strong>
              <div style="margin-top: 15px;">Créez un nouveau compte <strong style="color: #325ea9;">AFHunt</strong></div>
            </div>

              <div class="" style="padding: 15px 30px; border: 1px solid #ddd; border-radius: 15px; margin-top: 15px;">

                <div class="text-center" style="margin-bottom: 20px;">
                  <img src="assets/images/utilisateur-100.png" alt=".." style="height: 50px;"/>
                </div>

                <ngx-spinner
                  bdColor="rgba(51,51,51,0.8)"
                  size="medium"
                  color="#fff"
                  type="ball-scale-multiple"
                >

                <p style="font-size: 20px; color: white">Loading...</p>
                </ngx-spinner>

                <form style="max-width: 100%; display: block;"
                  name="form"
                  (ngSubmit)="onSubmit(f)"
                  #f="ngForm"
                  novalidate
                  >
                  <div class="row">
                    <div class="col-md-6">
                      <div class="alert-danger" *ngIf="f.submitted && firstName.invalid">
                        <div *ngIf="firstName.errors.required">Username is required</div>
                        <div *ngIf="firstName.errors.minlength">
                          Username must be at least 3 characters
                        </div>
                        <div *ngIf="firstName.errors.maxlength">
                          Username must be at most 50 characters
                        </div>
                      </div>

                      <div class="form-group mb-4">
                        <label for="firstName" class="sr-only">Nom de famille</label>
                        <input type="text" name="firstName" id="firstName" class="form-control" placeholder="Nom de famille"
                        [(ngModel)]="form.firstName"
                        required
                        minlength="3"
                        maxlength="50"
                        #firstName="ngModel"
                        />
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="alert-danger" *ngIf="f.submitted && lastName.invalid">
                        <div *ngIf="lastName.errors.required">Username is required</div>
                        <div *ngIf="lastName.errors.minlength">
                          Username must be at least 3 characters
                        </div>
                        <div *ngIf="lastName.errors.maxlength">
                          Username must be at most 50 characters
                        </div>
                      </div>
                      <div class="form-group mb-4">
                        <label for="lastName" class="sr-only">Prenom</label>
                        <input type="text" name="lastName" id="lastName" class="form-control" placeholder="Prénom"
                        [(ngModel)]="form.lastName"
                        required
                        minlength="3"
                        maxlength="50"
                        #lastName="ngModel"
                        />
                      </div>
                    </div>
                  </div>


                  <div class="alert-danger" *ngIf="f.submitted && f.controls.phone != null && (f.controls.phone.value == null || f.controls.phone.errors != null)">
                    <div >Numéro Invalide</div>
                  </div>
                  <div class="form-group">
                  <ngx-intl-tel-input
                      [cssClass]="'form-control'"
                      [preferredCountries]="[CountryISO.UnitedStates, CountryISO.UnitedKingdom]"
                      [enableAutoCountrySelect]="false"
                      [enablePlaceholder]="true"
                      [searchCountryFlag]="true"
                      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                      [selectFirstCountry]="false"
                      [selectedCountryISO]="CountryISO.Cameroon"
                      [maxLength]="15"
                      [phoneValidation]="true"
                      name="phone"
                      [(ngModel)]="form.phone"
                    ></ngx-intl-tel-input>
                  </div>

                  <div class="alert-danger" *ngIf="f.submitted && username.invalid">
                    <div *ngIf="username.errors.required">Username is required</div>
                    <div *ngIf="username.errors.minlength">
                      Username must be at least 3 characters
                    </div>
                    <div *ngIf="username.errors.maxlength">
                      Username must be at most 50 characters
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="username" class="sr-only">Email</label>
                    <input type="email" name="username" id="username" class="form-control" placeholder="Email"
                    [(ngModel)]="form.username"
                    required
                    minlength="3"
                    maxlength="50"
                    #username="ngModel"
                    [email]="true"
                    />
                  </div>

                  <div class="alert-danger" *ngIf="f.submitted && password.invalid">
                    <div *ngIf="password.errors.required">Password is required</div>
                    <div *ngIf="password.errors.minlength">
                      Password must be at least 5 characters
                    </div>
                  </div>

                  <div class="form-group mb-4">
                    <label for="password" class="sr-only">Password</label>
                    <input type="password" name="password" id="password-field" class="form-control" placeholder="Mot de passe"
                    [(ngModel)]="form.password"
                    required
                    minlength="5"
                    #password="ngModel"
                    />
                    <span toggle="#password-field" class="fa fa-fw fa-eye field-icon toggle-password"></span>
                  </div>

                  <div class="" style="margin-top: 15px;">
                    <button href="#" [disabled]="f.invalid" [ngClass]="{'isDisabled': (f.invalid || (f.controls.phone != null && f.controls.phone.value == null)), 'flat-butt flat-grad-butt': (!f.invalid && f.controls.phone != null && f.controls.phone.value != null) }">S'inscrire</button>
                    <!-- button href="#" class="flat-butt flat-grad-butt">S'inscrire</button -->
                  </div>

                  <div class="alert alert-warning" *ngIf="f.submitted && isSignUpFailed">
                    Signup failed!<br />{{ errorMessage }}
                  </div>

                  <p class="login-card-footer-text" style="margin-top: 15px;">Vous souhaitez plutôt  <a href="javascript:void(0)" class="text-reset" routerLink="/login/form"><strong class="important-link"> vous connectez à votre compte <span class="fa fa-angle-right"></span></strong></a></p>
                </form>

              </div>

              <div class="alert alert-success" *ngIf="isSuccessful">
                Logged in as {{ roles }}. {{ token }}
              </div>

              <nav class="login-card-footer-nav" style="border-top: 1px solid #ddd; margin-top: 15px;">
                En cliquant sur <strong>S'inscrire</strong> vous acceptez 
                <a href="#!">Nos conditions d'utilisations</a> et 
                <a href="#!">Notre politique de confidentialité</a> 
                qui indique comment les données collectées sont utilisées.
              </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
