
<main class="d-flex align-items-center min-vh-100 py-3 py-md-0" style="width: 100%;">
  <div class="container">
    <div class="card login-card">
      <div class="row no-gutters">
        <div class="col-md-5">
          
          <div class="login-card-img">
            <div class="text-center mg-t-xs-medium">
                <img src="../assets/images/img-login.jpg" alt="" style="max-width: 100%;"/>
            </div>

            <h3 class="app-title">
              Connectez vous à plus de 06 Applications avec  <strong style="color: #325ea9;">un seul compte AFHunt</strong>
            </h3>

            <div class="text-left">
              <span class="app-title-diviser"></span>
            </div>
          </div>
          
        </div>
        <div class="col-md-7">
          <div class="card-body">
            <div class="login-card-description" style="font-family: Raleway-Medium;">
              <strong style="color: #325ea9;">Connexion</strong>
              <div style="margin-top: 15px;">Connectez-vous à votre compte <strong style="color: #325ea9;">AFHunt</strong></div>
            </div>
            
            <ngx-spinner
              bdColor="rgba(51,51,51,0.8)"
              size="medium"
              color="#fff"
              type="ball-scale-multiple"
            >
              <p style="font-size: 20px; color: white">Loading...</p>
            </ngx-spinner>
            
            <form name="form"
            (ngSubmit)="f.form.valid && onSubmit()"
            #f="ngForm"
            novalidate style="display: block; min-width: 100%;">
              <div class="" style="padding: 15px 30px; border: 1px solid #ddd; border-radius: 15px; margin-top: 15px;">

                <div class="text-center" style="margin-bottom: 20px;">
                  <img src="assets/images/utilisateur-100.png" alt=".." style="height: 50px;"/>
                </div>

                <div class="form-group">
                  <select name="typecompte" class="form-control" [ngModel]="selectedDeviceObj" (ngModelChange)="onChangeObj($event)">
                    <option [value]="null" disabled>Type d'identifiant</option>
                    <option [ngValue]="i" *ngFor="let i of deviceObjects">{{i.name}}</option>
                  </select>
                </div>

                <div class="form-group">
                  <input
                  name="username"
                  [(ngModel)]="form.username"
                  required
                  #username="ngModel"
                  type="{{ selectedDeviceObj.type }}" class="form-control"
                  value="{{ selectedDeviceObj.default }}" placeholder="Entrez votre {{ selectedDeviceObj.name }}"/>

                  <div
                    class="alert alert-danger"
                    role="alert"
                    *ngIf="f.submitted && username.invalid">
                    Username is required!
                  </div>
                </div>

                <div class="form-group">
                  <input
                  type="password"
                  class="form-control"
                  name="password"
                  [(ngModel)]="form.password"
                  required
                  minlength="6"
                  #password="ngModel"
                  id="password-field" placeholder="Mot de passe"/>
                  <span toggle="#password-field" class="fa fa-fw fa-eye field-icon toggle-password"></span>

                  <div
                    class="alert alert-danger"
                    role="alert"
                    *ngIf="f.submitted && password.invalid"
                    >
                    <div *ngIf="password.errors.required">Password is required</div>
                    <div *ngIf="password.errors.minlength">
                      Password must be at least 6 characters
                    </div>
                  </div>
                </div>

                <div class="" style="margin-top: 15px;">
                  <button tupe="button" class="flat-butt flat-grad-butt" >Se connecter</button>
                  <a routerLink="/singlepage/reset/password" style="float: right;  margin-top: 20px;">Mot de passe oublié ?</a>
                </div>

                <p class="login-card-footer-text" style="margin-top: 15px;">Vous souhaitez <a routerLink="/registration/form" class="text-reset"><strong class="important-link">Créer un nouveau compte <span class="fa fa-angle-right"></span></strong></a></p>
              </div>
            </form>
            <nav class="login-card-footer-nav" style="border-top: 1px solid #ddd; margin-top: 15px;">
              En cliquant sur <strong>Se connecter</strong> vous acceptez 
              <a href="#!">Nos conditions d'utilisations</a> et 
              <a href="#!">Notre politique de confidentialité</a> 
              qui indique comment les données collectées sont utilisées.
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>