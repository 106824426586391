import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-singlepage',
  templateUrl: './singlepage.component.html',
  styleUrls: ['./singlepage.component.scss']
})
export class SinglepageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
