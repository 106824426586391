<div class="left-sidebar-lg-redim" style="background: #e8f0fe; position: fixed; border: 1px solid #ddd;">
  <div class="" style="min-height: 200px; margin-top: 90px;">

    <a routerLink="/dashboard/accueil" class="dash-link-radius">
      <span class="fa fa-dashcube"></span>
      Tableau de bord
    </a>

    <a routerLinkre="/dashboard/dev/applications" class="dash-link-radius">
      <span class="fa fa-plug"></span>
      Console développeurs
    </a>

    <a routerLink="/dashboard/historique/connexions" class="dash-link-radius">
      <span class="fa fa-exchange"></span>
      Historique des connexions
    </a>

    <a routerLink="liste/compte" class="dash-link-radius">
      <span class="fa fa-cog"></span>
      Gestions des comptes
    </a>

    <div style="position: absolute; bottom: 0px; width: 100%; padding: 5px 10px;">
      <div class="row">
        <div class="col-md-7">
          <a href="" class="small-text-gray">Conditions d'utilisation</a>
        </div>
        <div class="col-md-5">
          <a href="" class="small-text-gray">Confidentialité</a>
        </div>
      </div>
    </div>
  </div>
</div>
