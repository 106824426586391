import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-protectedasidebar',
  templateUrl: './protectedasidebar.component.html',
  styleUrls: ['./protectedasidebar.component.scss']
})
export class ProtectedasidebarComponent implements OnInit {

  constructor(){ }

  ngOnInit(): void {
  }

}
