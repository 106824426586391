<div id="main-panel-content" class="resize-lg-left-navigation" style="padding-top: 83px;">
  <div class="container">

    <h2>
      Gestions de paramètres de votre profil
      <a class="nav-link float-right" data-widget="control-sidebar" data-slide="true" href="#"><i class="fa fa-th-large"></i></a>
    </h2>

  <div class="mg-t-3">
    
      <div id="breadcrumb" class="breadcrumb-dash" style="border-bottom: 1px solid #ddd; margin-bottom: 15px; min-width: 100%;">
        <a href="#!" class="breadcrumb-close">Accueil</a>
        <a href="#!" class="breadcrumb-link">
          <img src="assets/images/home-96.png" alt="Home Icon CSU" style="height: 15px;">
          Accueil
        </a>
        <a href="#!" class="breadcrumb-link">Tableau de bord</a>
        <a href="#breadcrumb" class="breadcrumb--active">Active Level</a>
      </div>

      <div class="form-group" *ngIf="filedata != null">
        <div class="progress">
          <!-- div class="progress-bar" role="progressbar" style="width: {{ filedata.progress }}%" aria-valuenow="{{ filedata.progress }}" aria-valuemin="0" aria-valuemax="100"></div -->
        </div>
      </div>
    
  </div>
    <div class="row">
      <div class="col-md-6">
        <div class="card-dash-csu">
          <div class="liste-dash text-left">
            <img [src]="images.defaultprofil" alt="" class="form-detail-img-circle"/>
            <h2 class="title">
              <strong>Informations Publique</strong>
            </h2>
            <h3 class="subtitle">Informations utilisées pour l'identification de votre compte</h3>
          </div>

          <form name="form"
          (ngSubmit)="f.form.valid && onSubmit()"
          #f="ngForm">

            <div class="form-group">
              <input
              type="text"
              class="form-control dash-input-lg"
              name="nom"
              [(ngModel)]="form.nom"
              required
              minlength="2"
              #nom="ngModel"
              placeholder="Entrez votre nom de famille" [ngClass]="nom.touched ? (nom.invalid ? 'is-invalid' : 'is-valid') : ''"/>
             
              <div class="invalid-feedback" *ngIf="nom.invalid">
                <div *ngIf="nom.errors.required">Le champ Nom est requis</div>
                <div *ngIf="nom.errors.minlength">
                  Au moins 3 caractères
                </div>
              </div>
            </div>


            <div class="form-group">
              <input
              type="text"
              class="form-control dash-input-lg"
              name="prenom"
              [(ngModel)]="form.prenom"
              required
              minlength="2"
              #prenom="ngModel"
              placeholder="Entrez votre prenom"  [ngClass]="prenom.touched ? (prenom.invalid ? 'is-invalid' : 'is-valid') : ''"/>
             
              <div class="invalid-feedback" *ngIf="prenom.invalid">
                <div *ngIf="prenom.errors.required">Le champ Prenom est requis</div>
                <div *ngIf="prenom.errors.minlength">
                  Au moins 3 caractères
                </div>
              </div>
            </div>

            
            <div class="form-group" *ngIf="currentFileName">
              <div class="alert alert-success">{{ currentFileName }}</div>
            </div>

            <div class="row">
                <div class="col-md-6">

                  <div class="text-center img-profil-panel">
                    <img [src]="currentuser.imgprofil" alt="" *ngIf="currentuser && currentuser.imgprofil != '' && url == null" style="max-width: 100%; max-height: 100%;"/>

                    <img [src]="images.imgdefault" alt="" *ngIf="currentuser && currentuser.imgprofil == '' && url == null" style="max-width: 100%; max-height: 100%;"/>

                    <img [src]="url" height="200" *ngIf="url" style="max-width: 100%; max-height: 100%;"/>
                  </div>
                  
                </div>

                <div class="col-md-6">
                  <label for="import-imgprofil" class="text-center img-profil-import">
                    
                    <div>
                      <span class="fa fa-camera"></span>
                    </div>
                    <span>Importez une nouvelle photo de profil</span>

                  </label>
                  <input type="file" id="import-imgprofil" style="opacity: 0;" (change)="fileEvent($event)"/>
                </div>
            </div>




            <!-- <div style="text-align:center; margin-top: 100px; "><div style="margin-top:10px; width: 50%;">  
              <div>  
                  <ul>  
                      <li *ngFor="let file of files">  
                          <div class="progress">
                            <div class="progress-bar" role="progressbar" style="width: {{ file.progress }}%" aria-valuenow="{{ file.progress }}" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <span id="file-label">  
                                
                          </span>  
                      </li>  
                  </ul>  
              </div>  
              <div>  
                  <button mat-button color="warn" (click)="onClick()">  
                      <span>file_upload</span>  
                      Upload  
                  </button>  
              </div>  
          </div><input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" accept="image/*" style="display:none;" /></div> -->


            <div class="form-group">
              <button class="btn btn-primary">Envoyer <span class="fa fa-send"></span></button>
            </div>
          </form>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card-dash-csu">
          <div class="liste-dash text-left">
            <img [src]="images.defaultprofil" alt="" class="form-detail-img-circle"/>
            <h2 class="title">
              <strong>Informations privées du compte</strong>
            </h2>
            <h3 class="subtitle">Contacts de notifications</h3>
          </div>

          <form (ngSubmit)="onUpdateContact(fc)"
          #fc="ngForm"
          novalidate>
            <div class="row">
                <div class="col-md-6">

                <div class="form-group">
                    <input type="email" name="email" id="email" class="form-control" placeholder="Email" 
                    [(ngModel)]="formContact.email"
                    [ngClass]="email.touched ? (email.invalid ? 'is-invalid' : 'is-valid') : ''"
                    required
                    minlength="2"
                    maxlength="150"
                    email="true"
                    #email="ngModel"
                    />
                    <div class="invalid-feedback" *ngIf="email.invalid">
                      <div *ngIf="email.errors.required">Le champ Email est requis</div>
                      <div *ngIf="email.errors.minlength">
                        Au moins 3 caractères
                      </div>
                    </div>
                </div>


                </div>
                <div class="col-md-6">
                  
                  <div class="form-group">
                    <input type="text" name="telephone" id="telephone" class="form-control" placeholder="Téléphone" 
                    [(ngModel)]="formContact.telephone"
                    [ngClass]="telephone.touched ? (telephone.invalid ? 'is-invalid' : 'is-valid') : ''"
                    required
                    minlength="2"
                    maxlength="150"
                    #telephone="ngModel"
                    />
                    <div class="invalid-feedback" *ngIf="telephone.invalid">
                      <div *ngIf="telephone.errors.required">Le champ Téléphone est requis</div>
                      <div *ngIf="telephone.errors.minlength">
                        Au moins 3 caractères
                      </div>
                    </div>
                </div>

                </div>
            </div>
            <div class="form-group">
              <button class="btn btn-primary" [disabled]="fc.invalid">Envoyer <span class="fa fa-send"></span></button>
            </div>
          </form>
            <fieldset class="form__fieldset">
              <legend class="form__legend">
                <div class="libele-item-cart-follow">
                  Réinitialiser le mot de passe
                </div>
              </legend>
       
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <input type="email" class="form-control" placeholder="Actuel mot de passe"/>
                  </div>

                  <div class="form-group">
                    <input type="email" class="form-control" placeholder="Nouveau mot de passe"/>
                  </div>
                </div>
              </div>
           </fieldset>

            <div class="form-group mg-t-2">
              <button class="btn btn-primary">Envoyer <span class="fa fa-send"></span></button>
            </div>
          
        </div>
      </div>


      <div class="col-md-12 mg-t-3">
        <div class="card-dash-csu">
          <div class="liste-dash text-left">
            <img [src]="images.defaultprofil" alt="" class="form-detail-img-circle" style=""/>
            <h2 class="title">
              <strong>Préférences du compte</strong>
            </h2>
            <h3 class="subtitle">Contacts de notifications</h3>
          </div>

          <form action="" class="row">
            <div class="col-md-6">

              <fieldset class="form__fieldset">
                <legend class="form__legend">
                  <div class="libele-item-cart-follow">
                    Adresses de facturation
                  </div>
                </legend>
         
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <input type="email" class="form-control" placeholder="Ville"/>
                    </div>
  
                    <div class="form-group">
                      <input type="email" class="form-control" placeholder="Pays"/>
                    </div>
                  </div>
                </div>
             </fieldset>

            </div>

            <div class="col-md-6">
              <fieldset class="form__fieldset">
                <legend class="form__legend">
                  <div class="libele-item-cart-follow">
                    Préférences Lingustique
                  </div>
                </legend>
         
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <input type="email" class="form-control" placeholder="Email"/>
                    </div>
  
                    <div class="form-group">
                      <input type="email" class="form-control" placeholder="Téléphone"/>
                    </div>
                  </div>
                </div>
             </fieldset>
            </div>

            <div class="col-md-12">

              <div class="form-group mg-t-2">
                <button class="btn btn-primary">Envoyer <span class="fa fa-send"></span></button>
              </div>
              
            </div>

          </form>
        </div>
      </div>

    </div>

  </div>
</div>
