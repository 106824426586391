
<div id="main-panel-content" class="resize-lg-left-navigation" style="padding-top: 83px;">
      <div class="container">
    
      <h2>
        Tableau de bord
        <a class="nav-link float-right" data-widget="control-sidebar" data-slide="true" href="#"><i class="fa fa-th-large"></i></a>
      </h2>

    <div class="mg-t-3">
        <div id="breadcrumb" class="breadcrumb-dash" style="border-bottom: 1px solid #ddd; margin-bottom: 15px; min-width: 100%;">
        	<a href="#!" class="breadcrumb-close">Accueil</a>
        	<a href="#!" class="breadcrumb-link">
            <img src="assets/images/home-96.png" alt="Home Icon CSU" style="height: 15px;">
            Accueil
          </a>
        	<a href="#!" class="breadcrumb-link">Tableau de bord</a>
        	<a href="#breadcrumb" class="breadcrumb--active">Active Level</a>
        </div>
    </div>

    <div class="row">

      <div class="col-md-3">
        <div class="cardstat">
          <div class="card__header">
            <img src="assets/images/account-64.png" alt="" style="height: 70px;"/>
            <div class="card__header__info">
              <h2 class="title">
                <a routerLink="/dashboard/liste/compte">Comptes</a>
              </h2>
        	    <span>Liste des mes comptes</span>
            </div>
            <span
              class="card__header__actions mdi mdi-dots-horizontal"
              role="button"
              tabindex="1"
            >
            </span>
          </div>
          <div class="card__statistics">
            <h2 class="card__statistics__topic">Instagram</h2>
            <span class="card__statistics__value">50k</span>
            <div class="card__statistics__progress">
              <div class="fill"></div>
            </div>
          </div>
          <div class="card__statistics">
            <h2 class="card__statistics__topic">Facebook</h2>
            <span class="card__statistics__value">30k</span>
            <div class="card__statistics__progress secondary">
              <div class="fill" style="width: 30%;"></div>
            </div>
          </div>
          <div class="card__statistics">
            <h2 class="card__statistics__topic">Twitter</h2>
            <span class="card__statistics__value">50k</span>
            <div class="card__statistics__progress instagram">
              <div class="fill"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="cardstat">
          <div class="card__header">
            <img src="assets/images/app-connected.png" alt="" style="height: 70px;"/>
            <div class="card__header__info">
              <h2 class="title">
                <a routerLink="/dashboard/applis/autorisees">Applications</a>
              </h2>
        	    <span>Applications autorisées</span>
            </div>
            <span
              class="card__header__actions mdi mdi-dots-horizontal"
              role="button"
              tabindex="1"
            >
            </span>
          </div>
          <div class="card__statistics">
            <h2 class="card__statistics__topic">Instagram</h2>
            <span class="card__statistics__value">50k</span>
            <div class="card__statistics__progress">
              <div class="fill"></div>
            </div>
          </div>
          <div class="card__statistics">
            <h2 class="card__statistics__topic">Facebook</h2>
            <span class="card__statistics__value">30k</span>
            <div class="card__statistics__progress secondary">
              <div class="fill" style="width: 30%;"></div>
            </div>
          </div>
          <div class="card__statistics">
            <h2 class="card__statistics__topic">Twitter</h2>
            <span class="card__statistics__value">50k</span>
            <div class="card__statistics__progress instagram">
              <div class="fill"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="box-dash">
          <img src="assets/images/secure-account.png" alt="" style="float: left; margin-right: 7px; width: 30px;">
        	<h2 class="title">Confidentialité</h2>
        	<h3 class="subtitle">Nous protégons bien vos comptes</h3>
          <span class="number">
            <span class="chip primary">12</span>
          </span>

          <div class="row">
            <div class="col-md-6">
                <p class="text">Almost before we knew it, we had left the ground.</p>
            </div>
            <div class="col-md-6 pd-t-5">
              <img src="assets/images/app-policy.jpg" alt="" style="height: 150px;">
            </div>
          </div>


          <div class="text-center more-infos-box" style="box-shadow: 0px 1px 5px 0px #d4d8de;">
            Consulter
          </div>
        </div>
      </div>
    </div>


    <div class="card-dash-csu">

      <div class="liste-dash text-left">
        <img src="assets/images/account-64.png" alt="" style="float: left; margin-right: 7px; width: 30px;"/>
        <h2 class="title">Dernières Connexion</h2>
        <h3 class="subtitle">Liste des mes comptes</h3>

        <a routerLink="/dashboard/historique/connexions" class="more-infos">
            Voir plus
            <img src="assets/images/more-infos.svg" alt="" style="">
        </a>
      </div>

      <div class="table-responsive-vertical shadow-z-1" style="box-shadow:0px 0px 2px rgba(0,0,0,0.0005);">
      <table id="table" class="table table-hover table-mc-light-blue" style="margin: 0px 0px 15px 0px;">
        <thead>
        <tr>
          <th>Id Requête</th>
          <th>Application</th>
          <th>Date</th>
          <th>Détail</th>
          <th>Date</th>
          <th>Etat</th>
          <th>Action</th>
        </tr>
        </thead>
       <tbody>

         <tr *ngFor='let traceconnexion of traceconnexions; let i = index'>
           <td data-title="Id Requête">
             {{ traceconnexion.id }}
           </td>
           <td data-title="Application"> {{ traceconnexion.projet.nom }} </td>
           <td data-title="Compte">{{ traceconnexion.projet.date|date: "dd.MM.y" }}</td>
           <td data-title="Détail">60 500</td>
           <td data-title="DATE">{{ traceconnexion.date|date: "dd.MM.y" }}</td>
           <td data-title="STATUT">
             <span class="csu-badge csu-badge-warn">EN ATTENTE</span>
           </td>
           <td data-title="Action" style="white-space: nowrap;">
             <div class="row">
               <div class="col-md-4 col-xs-4 text-center">
                 <a href="#" class="action-tb-link">
                   <img src="assets/images/user-icon.svg" alt="" style=""/><br/>
                   <span>Profil App</span>
                 </a>
               </div>
               <div class="col-md-4 col-xs-4 text-center">
                 <a href="#" class="action-tb-link">
                   <img src="assets/images/download-paiement.svg" alt=""/><br/>
                   <span>Signaler</span>
                 </a>
               </div>
               <div class="col-md-4 col-xs-4 text-center">
                 <a href="#" class="action-tb-link" title="Navigateur, Appareil, IP">
                   <img src="assets/images/eye-consulter.svg" alt=""/><br/>
                   <span>Consulter</span>
                 </a>
               </div>
             </div>
           </td>
         </tr>

       </tbody>
      </table>
      </div>
    </div>

  </div>
</div>
