<footer class="footer-dash-user resize-pd-footer" style="">

  <div class="row">
    <div class="col-md-7">
      Copyright © Your Website 2020
      Made with <span class="fa fa-heart" style="color: #ff5406;"></span> by <a href="#!" style="color: #ff5406;" target="_blank">CSU</a>
    </div>

    <div class="col-md-5 text-right">
      <a href="#">CGU</a> | <a href="#">Aide</a>
    </div>
  </div>

</footer>
