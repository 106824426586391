
<main class="d-flex align-items-center min-vh-100 py-3 py-md-0" style="width: 100%;">


  <div class="container">
      <div class="row">
        <div class="col-md-12">

          <app-loaderhttp></app-loaderhttp>

          <div *ngIf="traceconnexion != null && traceconnexion.projet != null">

            <div class="profile-card-hover mb-3">
              <div class="profile-card__content" style="margin: 0px;">
                <div  class="friends-avatars">
                  <span>Connexion </span> <a href="javascript:void(0)">Rapide</a> • <a href="javascript:void(0)">Sécurisée</a>
                </div>
                <hr>
                <div class="about-company"> 
                  <a class="profile-card__avatar" href="javascript:void(0)"><img *ngIf="traceconnexion.projet.logoprojeturl  != null" src="{{ baseUrl }}{{ traceconnexion.projet.logoprojeturl }}" alt="Company Avatar" class="img-thumnail"/></a>
                  <div class="row-wrapper">
                    <div class="profile-card__company-name">Connectez vous sur <a href="javascript:void(0)">{{ traceconnexion.projet.nom }}</a></div>
                    <p class="profile-card__company-bio">Avec votre compte • <strong style="border-bottom: 2px dashed #ddd;">{{ currentuser.firstname +' '+currentuser.lastname }}</strong></p><a class="flat-butt flat-info-butt flat-info-grad-butt" *ngIf="currentuser != null" href="javascript:void(0)" (click)="validateDemande(traceconnexion, currentuser)">Accepter et continuer <span class="fa fa-angle-right"></span></a>
                  </div>
                </div>
                  <div *ngIf="currentuser == null" class="friends-avatars">
                  <strong>Vous n'avez pas de compte actif sur cette appareil</strong>

                  <a routerLink="/login/form" class="btn btn-primary">Connectez-vous <span class="fa fa-angle-right"></span></a>
                  </div>

                  <div *ngIf="currentuser != null" class="friends-avatars">
                    <span>Ils viennent de se connecter aussi:</span>
                    <a href="javascript:void(0)">Eric</a> • <a href="javascript:void(0)">Alain</a> • <a href="javascript:void(0)">Albert</a>
                  </div>
              </div>
            </div>
          </div>

          <div *ngIf="traceconnexion == null" class="text-center">
            <span class="logo-home-panel">
              <img [src]="images.logoafhunt"  alt="">
            </span>
          </div>

        </div>

        <div class="col-md-12">
          
            <div style="border: 3px dashed #ddd; margin-bottom: 20px; border-radius: 10px; padding: 7px;">
              <h1 class="dropdown-btn12" style="color: #fff; font-family: Philosopher-Bold; margin: 0px;">Choisissez un compte pour vous connectez</h1>
            </div>

            <div class="section_our_solution">
              <h2>Liste des comptes disponibles sur cet appareil</h2>
              <hr>
              <div class="our_solution_category">
                <div class="row solution_cards_box">
                  <div class="col-md-4" *ngFor="let account of listeaccount;">
                    <div class="solution_card">
                      <div class="hover_color_bubble"></div>
                      <img src="assets/images/utilisateur-100.png" alt=".." style="" class="account-profil"/>
                      <div class="solu_title">
                        <h3>{{ account.firstname }} {{ account.lastname }} </h3>
                      </div>
                      <div class="solu_description">
                        <p>
                          <strong>Email:</strong> {{ account.username }}
                        </p>
                        <div class="mt-3 text-right">
                          <a routerLink="/dashboard/accueil" *ngIf="account.connected_user == 1" class="flat-butt flat-info-butt flat-info-grad-butt float-right">Tableau de bord</a>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <!--  -->
              </div>
              <hr>
              <div style="margin-top: 15px;">
                <h2>Vous ne trouvez pas le compte approprié ? <a routerLink="/login/form" class="important-link">Utilisez un autre compte</a></h2>
                
              </div>
            </div>

          
        </div>
      </div>
    </div>
  </main>
