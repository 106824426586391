<div id="main-panel-content" class="resize-lg-left-navigation" style="padding-top: 83px;">
  <div class="container">

    <div class="overview-page" style="">
      <h2>Historique des connexions</h2>
    </div>


    <div class="row mg-t-3 ">
      <div class="col-md-12 ">
        <div id="breadcrumb" class="breadcrumb-dash" style="border-bottom: 1px solid #ddd; margin-bottom: 15px;">
        	<a href="#!" class="breadcrumb-close">Accueil</a>
        	<a href="#!" class="breadcrumb-link">
            <img src="assets/images/home-96.png" alt="Home Icon CSU" style="height: 15px;">
            Accueil
          </a>
        	<a href="#!" class="breadcrumb-link">Tableau de bord</a>
        	<a href="#breadcrumb" class="breadcrumb--active">Active Level</a>
        </div>
      </div>
    </div>

    <div class="panel-search-form">
      <form class="row" method="post" action="">
        <div class="col-md-3">
          <div class="form-group">
            <select name="" class="form-control dash-text-input">
              <option value="">Choisir le statut</option>
            </select>
          </div>
        </div>
        <div class="offset-md-5 col-md-4">
          <div class="input-group">
            <input class="form-control dash-text-input" name="" placeholder="Recherche">
            <div class="input-group-append">
              <button class="input-group-text btn-text-submit">
                <span class="fa fa-search"></span> Rechercher
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="card-dash-csu">
      <div class="liste-dash text-left">
        <img src="assets/images/account-64.png" alt="" style="float: left; margin-right: 7px; width: 30px;"/>
        <h2 class="title">Dernières Connexion</h2>
        <h3 class="subtitle">Liste des mes comptes</h3>

        <a href="#" class="more-infos">
            Voir plus
            <img src="/CSU/public/images/more-infos.svg" alt="" style="">
        </a>
      </div>

      <div class="table-responsive-vertical shadow-z-1" style="box-shadow:0px 0px 2px rgba(0,0,0,0.0005);">
        <table id="table" class="table table-hover table-mc-light-blue" style="margin: 0px 0px 15px 0px;">
          <thead>
          <tr>
            <th>Id Requête</th>
            <th>Application</th>
            <th>Compte</th>
            <th>Détail</th>
            <th>Date</th>
            <th>Etat</th>
            <th>Action</th>
          </tr>
          </thead>
         <tbody>

           <tr>
             <td data-title="Id Requête">
               1804-0180-3426-8
             </td>
             <td data-title="Application"> Ndikum Brice Lambi </td>
             <td data-title="Compte">Noel.kenfack</td>
             <td data-title="Détail">60 500</td>
             <td data-title="DATE">13/12/2020</td>
             <td data-title="STATUT">
               <span class="csu-badge csu-badge-warn">EN ATTENTE</span>
             </td>
             <td data-title="Action" style="white-space: nowrap;">
               <div class="row">
                 <div class="col-md-4 col-xs-4 text-center">
                   <a href="#" class="action-tb-link">
                     <img src="assets/images/user-icon.svg" alt="" style=""/><br/>
                     <span>Profil App</span>
                   </a>
                 </div>
                 <div class="col-md-4 col-xs-4 text-center">
                   <a href="#" class="action-tb-link">
                     <img src="assets/images/download-paiement.svg" alt=""/><br/>
                     <span>Signaler</span>
                   </a>
                 </div>
                 <div class="col-md-4 col-xs-4 text-center">
                   <a href="#" class="action-tb-link" title="Navigateur, Appareil, IP">
                     <img src="assets/images/eye-consulter.svg" alt=""/><br/>
                     <span>Consulter</span>
                   </a>
                 </div>
               </div>
             </td>
           </tr>

           <tr>
             <td data-title="Id Requête">
               1801-0181-1501-0
             </td>
             <td data-title="Application"> Tankou Tatah Stéphane </td>
             <td data-title="Compte">Noel.kenfack</td>
             <td data-title="Détail">60 500</td>
             <td data-title="DATE">13/12/2020</td>
             <td data-title="STATUT">
               <span class="csu-badge csu-badge-success">Validé</span>
             </td>
             <td data-title="Action" style="white-space: nowrap;">
               <div class="row">
                 <div class="col-md-4 col-xs-4 text-center">
                   <a href="#" class="action-tb-link">
                     <img src="assets/images/user-icon.svg" alt="" style=""/><br/>
                     <span>Profil App</span>
                   </a>
                 </div>
                 <div class="col-md-4 col-xs-4 text-center">
                   <a href="#" class="action-tb-link">
                     <img src="assets/images/download-paiement.svg" alt=""/><br/>
                     <span>Signaler</span>
                   </a>
                 </div>
                 <div class="col-md-4 col-xs-4 text-center">
                   <a href="#" class="action-tb-link" title="Navigateur, Appareil, IP">
                     <img src="assets/images/eye-consulter.svg" alt=""/><br/>
                     <span>Consulter</span>
                   </a>
                 </div>
               </div>
             </td>
           </tr>

           <tr>
             <td data-title="Id Requête">
               1801-0181-1501-0
             </td>
             <td data-title="Application"> Ateba Atanga Josiane </td>
             <td data-title="Compte">Noel.kenfack</td>
             <td data-title="Détail">60 500</td>
             <td data-title="DATE">13/12/2019</td>
             <td data-title="STATUT">
               <span class="csu-badge csu-badge-primary">Payé</span>
             </td>
             <td data-title="Action" style="white-space: nowrap;">
               <div class="row">
                 <div class="col-md-4 col-xs-4 text-center">
                   <a href="#" class="action-tb-link">
                     <img src="assets/images/user-icon.svg" alt="" style=""/><br/>
                     <span>Profil App</span>
                   </a>
                 </div>
                 <div class="col-md-4 col-xs-4 text-center">
                   <a href="#" class="action-tb-link">
                     <img src="assets/images/download-paiement.svg" alt=""/><br/>
                     <span>Signaler</span>
                   </a>
                 </div>
                 <div class="col-md-4 col-xs-4 text-center">
                   <a href="#" class="action-tb-link" title="Navigateur, Appareil, IP">
                     <img src="assets/images/eye-consulter.svg" alt=""/><br/>
                     <span>Consulter</span>
                   </a>
                 </div>
               </div>
             </td>
           </tr>

           <tr>
             <td data-title="Id Requête">
               1801-0181-1501-0
             </td>
             <td data-title="Application"> Ateba Atanga Josiane </td>
             <td data-title="Compte">Noel.kenfack</td>
             <td data-title="Détail">60 500</td>
             <td data-title="DATE">13/12/2019</td>
             <td data-title="STATUT">
               <span class="csu-badge csu-badge-danger">Rejeté</span>
             </td>
             <td data-title="Action" style="white-space: nowrap;">
               <div class="row">
                 <div class="col-md-4 col-xs-4 text-center">
                   <a href="#" class="action-tb-link">
                     <img src="assets/images/user-icon.svg" alt="" style=""/><br/>
                     <span>Profil App</span>
                   </a>
                 </div>
                 <div class="col-md-4 col-xs-4 text-center">
                   <a href="#" class="action-tb-link">
                     <img src="assets/images/download-paiement.svg" alt=""/><br/>
                     <span>Signaler</span>
                   </a>
                 </div>
                 <div class="col-md-4 col-xs-4 text-center">
                   <a href="#" class="action-tb-link" title="Navigateur, Appareil, IP">
                     <img src="assets/images/eye-consulter.svg" alt=""/><br/>
                     <span>Consulter</span>
                   </a>
                 </div>
               </div>
             </td>
           </tr>

           <tr>
             <td data-title="Id Requête">
               1804-0180-3426-8
             </td>
             <td data-title="Application"> Ndikum Brice Lambi </td>
             <td data-title="Compte">Noel.kenfack</td>
             <td data-title="Détail">60 500</td>
             <td data-title="DATE">13/12/2020</td>
             <td data-title="STATUT">
               <span class="csu-badge csu-badge-warn">EN ATTENTE</span>
             </td>
             <td data-title="Action" style="white-space: nowrap;">
               <div class="row">
                 <div class="col-md-4 col-xs-4 text-center">
                   <a href="#" class="action-tb-link">
                     <img src="assets/images/user-icon.svg" alt="" style=""/><br/>
                     <span>Profil App</span>
                   </a>
                 </div>
                 <div class="col-md-4 col-xs-4 text-center">
                   <a href="#" class="action-tb-link">
                     <img src="assets/images/download-paiement.svg" alt=""/><br/>
                     <span>Signaler</span>
                   </a>
                 </div>
                 <div class="col-md-4 col-xs-4 text-center">
                   <a href="#" class="action-tb-link" title="Navigateur, Appareil, IP">
                     <img src="assets/images/eye-consulter.svg" alt=""/><br/>
                     <span>Consulter</span>
                   </a>
                 </div>
               </div>
             </td>
           </tr>

         </tbody>
        </table>
      </div>
    </div>


    <div class="row">
      <div class="col-md-6 offset-md-3">
        <div class="panel-paginator-dash" style="">

        <nav aria-label="Page navigation example" style="display: inline-block;">
          <ul class="pagination">
            <li class="page-item"><a class="page-link" href="#">Précédent</a></li>
            <li class="page-item active"><a class="page-link" href="#">1</a></li>
            <li class="page-item"><a class="page-link" href="#">2</a></li>
            <li class="page-item"><a class="page-link" href="#">3</a></li>
            <li class="page-item"><a class="page-link" href="#">Suivant</a></li>
          </ul>
        </nav>

        </div>
      </div>
    </div>

  </div>
</div>
