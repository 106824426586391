<div id="main-panel-content" class="resize-lg-left-navigation" style="padding-top: 83px;">
  <div class="container">

    <h2>
      Gestions de mes applications
      <a class="btn btn-primary float-right mg-t-2" (click)="openModal()">
        <i class="fa fa-plus"></i> Ajouter une appli
      </a>
    </h2>

    <div class="mg-t-3">
      
        <div id="breadcrumb" class="breadcrumb-dash" style="border-bottom: 1px solid #ddd; margin-bottom: 15px; min-width: 100%;">
          <a href="#!" class="breadcrumb-close">Accueil</a>
          <a href="#!" class="breadcrumb-link">
            <img src="assets/images/home-96.png" alt="Home Icon CSU" style="height: 15px;">
            Accueil
          </a>
          <a href="#!" class="breadcrumb-link">Tableau de bord</a>
          <a href="#breadcrumb" class="breadcrumb--active">Active Level</a>
        </div>
      
    </div>

    <app-loaderhttp></app-loaderhttp>
 
    <div class="row">
     <div class="col-md-4" *ngFor='let projet of projets; let i = index'>
      <div class="eachCard">
        <div class="cardContent">
          <div class="card-content">
            <i class="fa fa-google icono" aria-hidden="true"></i>
            <h3>
              <strong>{{ projet.nom }}</strong>
            </h3>
            <p>
              {{ projet.description }}
            </p>
            <div class="info">
              <div class="detail">
                <i class="fa fa-arrow-down" aria-hidden="true"></i>
                <span>35,444</span>
              </div>
              <div class="detail">
                <i class="fa fa-star" aria-hidden="true"></i>
                <span>35,444</span>
              </div>
            </div>
          </div>
        </div>
        <div class="userActions">
            <a href="" class="btn-secondary button-app"><svg width="1em" height="1em" viewBox="0 0 16 16"
                    class="bi bi-bookmark" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z" />
                </svg> 
                Connexion
              </a>
            <a class="btn-primary button-app" [routerLink]="['/dashboard/dev/detail/appli', projet.id ]">
                <a href="eachCard.apiLink" target="_blank" rel="noopener noreferrer">Personnaliser</a> <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right"
                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                </svg>
            </a>
        </div>
        </div>
     </div>
   </div>

  <app-overlay></app-overlay>
  <app-modalappli></app-modalappli>

    <div class="card-dash-csu">
      <div class="liste-dash text-left">
        <img src="assets/images/account-64.png" alt="" style="float: left; margin-right: 7px; width: 30px;"/>
        <h2 class="title">Dernières Connexion</h2>
        <h3 class="subtitle">Liste des mes comptes</h3>

        <a href="#" class="more-infos">
            Voir plus
            <img src="assets/images/more-infos.svg" alt="" style="">
        </a>
      </div>

      <div class="table-responsive-vertical shadow-z-1" style="box-shadow:0px 0px 2px rgba(0,0,0,0.0005);">
      <table id="table" class="table table-hover table-mc-light-blue" style="margin: 0px 0px 15px 0px;">
        <thead>
        <tr>
          <th>Id Requête</th>
          <th>Application</th>
          <th>Détail</th>
          <th>Date</th>
          <th>Etat</th>
          <th>Action</th>
        </tr>
        </thead>
       <tbody>

         <tr>
           <td data-title="Id Requête">
             1804-0180-3426-8
           </td>
           <td data-title="Application"> Ndikum Brice Lambi </td>
           <td data-title="Détail">60 500</td>
           <td data-title="DATE">13/12/2020</td>
           <td data-title="STATUT">
             <span class="csu-badge csu-badge-warn">EN ATTENTE</span>
           </td>
           <td data-title="Action" style="white-space: nowrap;">
             <div class="row">
               <div class="col-md-4 col-xs-4 text-center">
                 <a href="#" class="action-tb-link">
                   <img src="assets/images/user-icon.svg" alt="" style=""/><br/>
                   <span>Profil App</span>
                 </a>
               </div>
               <div class="col-md-4 col-xs-4 text-center">
                 <a href="#" class="action-tb-link">
                   <img src="assets/images/download-paiement.svg" alt=""/><br/>
                   <span>Signaler</span>
                 </a>
               </div>
               <div class="col-md-4 col-xs-4 text-center">
                 <a href="#" class="action-tb-link" title="Navigateur, Appareil, IP">
                   <img src="assets/images/eye-consulter.svg" alt=""/><br/>
                   <span>Consulter</span>
                 </a>
               </div>
             </div>
           </td>
         </tr>

         <tr>
           <td data-title="Id Requête">
             1801-0181-1501-0
           </td>
           <td data-title="Application"> Tankou Tatah Stéphane </td>
           <td data-title="Détail">60 500</td>
           <td data-title="DATE">13/12/2020</td>
           <td data-title="STATUT">
             <span class="csu-badge csu-badge-success">Validé</span>
           </td>
           <td data-title="Action" style="white-space: nowrap;">
             <div class="row">
               <div class="col-md-4 col-xs-4 text-center">
                 <a href="#" class="action-tb-link">
                   <img src="assets/images/user-icon.svg" alt="" style=""/><br/>
                   <span>Profil App</span>
                 </a>
               </div>
               <div class="col-md-4 col-xs-4 text-center">
                 <a href="#" class="action-tb-link">
                   <img src="assets/images/download-paiement.svg" alt=""/><br/>
                   <span>Signaler</span>
                 </a>
               </div>
               <div class="col-md-4 col-xs-4 text-center">
                 <a href="#" class="action-tb-link" title="Navigateur, Appareil, IP">
                   <img src="assets/images/eye-consulter.svg" alt=""/><br/>
                   <span>Consulter</span>
                 </a>
               </div>
             </div>
           </td>
         </tr>

         <tr>
           <td data-title="Id Requête">
             1801-0181-1501-0
           </td>
           <td data-title="Application"> Ateba Atanga Josiane </td>
           <td data-title="Détail">60 500</td>
           <td data-title="DATE">13/12/2019</td>
           <td data-title="STATUT">
             <span class="csu-badge csu-badge-primary">Payé</span>
           </td>
           <td data-title="Action" style="white-space: nowrap;">
             <div class="row">
               <div class="col-md-4 col-xs-4 text-center">
                 <a href="#" class="action-tb-link">
                   <img src="assets/images/user-icon.svg" alt="" style=""/><br/>
                   <span>Profil App</span>
                 </a>
               </div>
               <div class="col-md-4 col-xs-4 text-center">
                 <a href="#" class="action-tb-link">
                   <img src="assets/images/download-paiement.svg" alt=""/><br/>
                   <span>Signaler</span>
                 </a>
               </div>
               <div class="col-md-4 col-xs-4 text-center">
                 <a href="#" class="action-tb-link" title="Navigateur, Appareil, IP">
                   <img src="assets/images/eye-consulter.svg" alt=""/><br/>
                   <span>Consulter</span>
                 </a>
               </div>
             </div>
           </td>
         </tr>

         <tr>
           <td data-title="Id Requête">
             1801-0181-1501-0
           </td>
           <td data-title="Application"> Ateba Atanga Josiane </td>
           <td data-title="Détail">60 500</td>
           <td data-title="DATE">13/12/2019</td>
           <td data-title="STATUT">
             <span class="csu-badge csu-badge-danger">Rejeté</span>
           </td>
           <td data-title="Action" style="white-space: nowrap;">
             <div class="row">
               <div class="col-md-4 col-xs-4 text-center">
                 <a href="#" class="action-tb-link">
                   <img src="assets/images/user-icon.svg" alt="" style=""/><br/>
                   <span>Profil App</span>
                 </a>
               </div>
               <div class="col-md-4 col-xs-4 text-center">
                 <a href="#" class="action-tb-link">
                   <img src="assets/images/download-paiement.svg" alt=""/><br/>
                   <span>Signaler</span>
                 </a>
               </div>
               <div class="col-md-4 col-xs-4 text-center">
                 <a href="#" class="action-tb-link" title="Navigateur, Appareil, IP">
                   <img src="assets/images/eye-consulter.svg" alt=""/><br/>
                   <span>Consulter</span>
                 </a>
               </div>
             </div>
           </td>
         </tr>

         <tr>
           <td data-title="Id Requête">
             1804-0180-3426-8
           </td>
           <td data-title="Application"> Ndikum Brice Lambi </td>
           <td data-title="Détail">60 500</td>
           <td data-title="DATE">13/12/2020</td>
           <td data-title="STATUT">
             <span class="csu-badge csu-badge-warn">EN ATTENTE</span>
           </td>
           <td data-title="Action" style="white-space: nowrap;">
             <div class="row">
               <div class="col-md-4 col-xs-4 text-center">
                 <a href="#" class="action-tb-link">
                   <img src="assets/images/user-icon.svg" alt="" style=""/><br/>
                   <span>Profil App</span>
                 </a>
               </div>
               <div class="col-md-4 col-xs-4 text-center">
                 <a href="#" class="action-tb-link">
                   <img src="assets/images/download-paiement.svg" alt=""/><br/>
                   <span>Signaler</span>
                 </a>
               </div>
               <div class="col-md-4 col-xs-4 text-center">
                 <a href="#" class="action-tb-link" title="Navigateur, Appareil, IP">
                   <img src="assets/images/eye-consulter.svg" alt=""/><br/>
                   <span>Consulter</span>
                 </a>
               </div>
             </div>
           </td>
         </tr>

       </tbody>
      </table>
      </div>
    </div>

  </div>
</div>
