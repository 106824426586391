<!-- Modal -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form name="form"
          (ngSubmit)="f.form.valid && onSubmit()"
          #f="ngForm">
        <div class="modal-body">
          <h3>Ajouter un nouveau client web</h3>
  
          
            <div class="form-group">
              <input type="text"
              class="form-control dash-input-lg"
              name="nom"
              [(ngModel)]="form.nom"
              required
              minlength="2"
              #nom="ngModel"
              placeholder="Entrez le nom de l'Application" [ngClass]="nom.touched ? (nom.invalid ? 'is-invalid' : 'is-valid') : ''"/>
            
              <div class="invalid-feedback" *ngIf="nom.invalid">
                <div *ngIf="nom.errors.required">Le champ Nom est requis</div>
                <div *ngIf="nom.errors.minlength">
                  Au moins 3 caractères
                </div>
              </div>
            </div>
  
            <div class="form-group">
              <textarea name="" id="" cols="30" rows="2" class="form-control dash-input-lg"
              name="description"
              [(ngModel)]="form.description"
              required
              minlength="2"
              #description="ngModel"
              placeholder="Description de l'application"  [ngClass]="description.touched ? (description.invalid ? 'is-invalid' : 'is-valid') : ''"></textarea>
            
              <div class="invalid-feedback" *ngIf="description.invalid">
                <div *ngIf="description.errors.required">Le champ Description est requis</div>
                <div *ngIf="description.errors.minlength">
                  Au moins 3 caractères
                </div>
              </div>
            </div>
  
            <div class="custom-file">
              <input type="file" class="custom-file-input" id="customFile" (change)="fileEvent($event)" required />
              <label class="custom-file-label" for="customFile">{{ currentFileName }}</label>
            </div>
  
        </div>
        <div class="modal-footer">
          <a type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</a>
          <button type="submit" class="btn btn-primary" [disabled]="!f.form.valid || filedata == null">Créer le projet</button>
        </div>
      </form>
      </div>
    </div>
  </div>